$version-control: '?v=#{random(888)}';

@font-face {
  font-family: Slackey;
  src: url('./Slackey-Regular.ttf');
  font-weight: 400;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: SlacksideOne;
  src: url('./SlacksideOne-Regular.ttf');
  font-weight: 400;
  font-style: normal;
  font-display: fallback;
}
@font-face {
  font-family: Sniglet;
  src: url('./Sniglet-Regular.ttf');
  font-weight: 400;
  font-style: normal;
  font-display: fallback;
}
@font-face {
  font-family: Sniglet;
  src: url('./Sniglet-ExtraBold.ttf');
  font-weight: 800;
  font-style: normal;
  font-display: fallback;
}