@use 'mixins';

.game-info {
  input, textarea {
    border-radius: 8px;
    margin: 0.15rem 0;
    outline: none;
    border: 1px solid white;
    background-color: $editable-input;
    color: white;
  }

  input {
    font-size: 2rem;
    font-weight: bold;
  }

  textarea {
    min-height: 4rem;
  }
}

.game-container.editor {
  background-color: $editor-background;
  position: relative;
  z-index: 2;
  padding-bottom: 2rem;
  @include scrollbar(rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.2));

  .game-content {
    height: 43vh;
  }

  &.crossword {
    max-height: unset;
  }

  textarea {
    border-color: #ffffff;
    width: 100%;
    min-height: 7rem;
    @include scrollbar(rgba(64, 38, 38, 0.1), rgba(50, 46, 46, 0.2));
  }

  input {
    border: 1px solid white;
  }

  textarea, input {
    color: white;
    border-radius: 8px;
    background-color: $editable-textarea;
    outline: none;
  }

  .with-count {
    position: unset;

    button {
      padding: 0.8rem 2rem;
    }

    > div button:not(.active) {
      background-color: #E5E5E54D;
      margin: 0 0.2rem;
    }
  }

  .main-btns {
    button {
      font-size: 1.25rem;
      border-radius: 0.75rem;
    }

    .btn-hint {
      border: 2px solid white;
      color: white;
    }

    .btn-delete {
      margin-right: 0.2rem;

      svg {
        width: 2.75rem;
      }
    }

    .slide-btns {
      button {
        padding: 0.5rem;
        min-width: 8rem;

        &:first-of-type {
          margin-right: 0.75rem;
        }
      }
    }
  }
}

.configs {
  padding: 4rem 2rem 2rem;
  margin-top: -2rem;
  background-color: #CCCCCC;
  min-height: 8rem;
  border-radius: inherit;
  //cursor: not-allowed;

  .config-item {
    justify-content: space-between;
    align-items: center;
    margin: 0.2rem 0;
    //pointer-events: none;

    label {
      font-size: 1.25rem;
      font-weight: bold;
      color: #5B5B5B;

      span {
        font-size: 0.725rem;
      }
    }

    input {
      font-weight: bold;
      min-width: 55%;
      border: none;
      border-radius: 6px;
      background-color: #D9D9D9;
      @include placeholderColor(#B3B3B3);
      &::placeholder{
        font-size: 0.75rem;
      }
    }
    select{
      border-radius: 0.75rem;
      border:1px solid;
      background: transparent url(../images/gamification/edit-icon.svg) no-repeat 125%;
      background-size: 50% 50%;
      cursor: pointer;
      text-transform: capitalize;
    }
  }

  .btn-outline-success {
    border-radius: 0.95rem;
    border-width: 3px;
  }
}

.editor-btns {

  button {
    font-size: 1.5rem;
    color: white;
    border-radius: 0.75rem;

    &.btn-secondary {
      background-color: rgba(91, 91, 91, 0.22);
      border-color: rgba(91, 91, 91, 0.22);

      &:hover {
        background-color: rgba(91, 91, 91, 0.12);
        color: black;
      }
    }

    &.btn-warning {
      background-color: #FF9900;
    }
  }

}

.single-quiz-editor {
  @include placeholderColor(rgba(255, 255, 255, 0.59));

  .options {
    padding: 1rem 1rem 0;

    p.instruction {
      color: $text-green;
      margin-top: 1rem;
      margin-bottom: 0;
    }
  }
}

.single-quiz-editor, .quiz-editor {
  .uploaded {
    padding-bottom: 60%;
  }
}

.calculation-game-editor {
  input {
    width: 100%;
    border-radius: 12px;
    border: 1px solid white;
    background-color: $editable-textarea;
    padding: 2rem 1rem;
  }

  .uploader {
    margin-bottom: 2rem;

    &.uploaded {
      padding-bottom: 60%;
    }
  }
}

.drag-drop-image-editor {
  input {
    width: calc(100% - 0.5px);
    border-radius: 15px;
    border: 1px solid white;
    background-color: $editable-textarea;
    padding: 2rem 1rem 4rem;
  }

  .uploader {
    top: -3rem;

    &.uploaded {
      padding-bottom: 61%;
    }
  }
}

.right-bottom-btn {
  display: flex;
  height: 100%;
  margin-top: 1rem;
  justify-content: flex-end;
  align-items: flex-end;
}

.middle-bottom-btn {
  display: flex;
  height: 100%;
  margin-top: 1rem;
  justify-content: center;
}

.left-bottom-btn {
  display: flex;
  height: 100%;
  margin: 1rem;
  justify-content: flex-start;
  align-items: center;

  .form-switch {
    margin-left: 1rem;

    .form-check-input {
      &:checked {
        background-color: #198754;
        border-color: #198754;
      }

      &:focus {
        @include mixins.box-shadow(0, 0, 0.25rem, rgb(25 135 84 / 22%));
      }
    }
  }
}

.game-picker.editor {
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;

  .game-type {
    flex-basis: 31.3%;
    font-size: 2rem;
    background-color: #ffffff;
    align-items: center;
    @include mixins.box-shadow(0, 4px, 4px, 0, #00000040);
    margin: 0.5rem;
    border-radius: 10px;

    button {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      height: 100%;
      padding: 2rem;

      &:hover, &.active {
        background-color: $text-green;

        svg {
          path {
            fill: #ffffff;
          }
        }
      }
    }

    svg {
      max-height: 2.5rem;
    }

    &.disabled {
      opacity: 0.5;
      pointer-events: none;
    }
  }
}

.submit-btn {
  button {
    font-size: 2rem;
    display: block;
    width: 100%;
    padding: 2.5rem 0 0.5rem;
    margin-top: -2rem;
    border-radius: 15px;

  }
}

.crossword-editor {
  padding-bottom: 4rem;

  .horizontal-scroll {
    overflow: auto;
    padding-bottom: 1rem;
    @include scrollbar(rgba(255, 255, 255, 0.1), rgb(255, 255, 255));
  }

  .crossword-grid {
    display: grid;
    grid-gap: 0.25rem;

    > div {
      height: 3rem;

      input {
        width: 100%;
        height: 100%;
        text-align: center;
        text-transform: capitalize;
        font-size: 1.25rem;
        font-weight: bold;
      }
    }
  }

  .row-counts, .column-counts {
    display: grid;
    grid-gap: 0.25rem;
    text-align: center;

    h3 {
      font-weight: bold;
    }
  }

  .column-counts {
    margin-left: 2.75rem;
  }

  .row-counts {
    h3 {
      display: flex;
      align-items: center;
      margin-bottom: 0;
      margin-right: 1rem;
    }
  }

  .configs-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;

    .crossword-configs {
      text-align: right;

      > div:not(.right-bottom-btn) {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 0.5rem 0;

        label {
          font-size: 1.5rem;
          font-weight: bold;
        }
      }
    }
  }

  .clue-popup {
    max-width: 50%;
    background-color: rgba(217, 217, 217, 0.9);
    @include mixins.box-shadow(0, 4px, 4px, 0, #00000040);
    position: fixed;
    left: 25%;
    padding: 1rem;
    border-radius: 7px;
    z-index: 2;
    top:0;


    h2 {
      color: $text-green;
      text-transform: capitalize;
      font-weight: bold;
    }

    button {
      width: 100%;
      padding: 0.5rem;
      font-size: 1.5rem;

      &:not(:last-of-type) {
        margin-bottom: 0.6rem;
      }

      &.direction-btn {
        background-color: $light-green;
        color: white;
      }
    }

    textarea {
      background-color: #ffffff;
      margin-bottom: 0.2rem;
      color: black;
      font-weight: bold;
      @include placeholderColor(rgba(0, 0, 0, 0.6));
    }

    label {
      width: 100%;
      background-color: #63CDF5;
      padding: 0.5rem;
      font-size: 1.5rem;
      text-align: center;
      font-weight: bold;
      margin: 0.13rem 0 0.5rem;
      border-radius: 0.375rem;
    }

    input[type="file"] {
      display: none;
    }
  }

  label {
    text-transform: uppercase;
  }

  .remove-btn, .add-btn {
    button {
      border-radius: 50%;
      border: none;
      color: white;
      font-size: 1.2rem;
      font-weight: bold;
      padding: 0.2rem 0.8rem;
    }
  }

  .remove-btn {
    button {
      background-color: red;
    }
  }

  .add-btn {
    button {
      background-color: $text-green;
    }
  }

}

.clues-info {
  background-color: $text-green;
  border-radius: 1rem;
  min-height: 5rem;
  padding: 1rem 0;

  ol, ul {
    max-height: 6rem;
    overflow-y: auto;
    overflow-x: hidden;
    direction: rtl;
    @include scrollbar(rgba(255, 255, 255, 0.1), rgb(255, 255, 255));

    li {
      list-style: auto;
      direction: ltr;
      text-align: left;
      padding: 0 0.5rem;
      word-break: break-word;
    }
  }

  h3 {
    font-size: 1rem;
    font-weight: bold;
    padding-left: 0.85rem;
  }
}

.editor {
  .fill-in-blanks-editor, .jumble-word-editor {
    textarea {
      min-height: calc(100% - 4rem);
    }

    .uploader {

      .upload-btn {
        max-width: 4rem;
        top: calc(50% - 2rem);
        left: calc(50% - 2rem);
      }
    }
  }

  .fill-in-blanks-editor {
    .uploader {
      &.uploaded {
        padding-bottom: 127.5%;
      }
    }
  }

  .jumble-word-editor {
    .select-text{
      position: absolute;
      color: black;
      z-index: 2;
      font-size: 2rem;
      font-weight: bold;
      left: calc(50% - 4.5rem);
      top: calc(50% - 1.5rem);
      cursor: pointer;
    }
    .uploader {
      .upload-btn{
        left:50%;
      }
      &.uploaded {
        padding-bottom: 61%;
      }
    }
  }
}

.show-hints-btn {
  background-color: $light-green;
  color: white;
  font-size: 1.75rem;
  margin: 0 0.75rem;

  &:hover {
    background-color: rgb(140 198 63 / 60%);
  }
}

.btn-wrapper.d-flex {
  position: absolute;
  bottom: 2rem;
}

.game-order-list {
  max-height: 70vh;
  @include scrollbar(#D9D9D9, #EEEEEE);

  li {
    background-color: $text-green;
    margin: 0.35rem;
    padding: 0.5rem;
    color: white;
    font-weight: bold;
    border-radius: 0.2rem;
  }
}

.attach-btn{
  color:$text-green;
  &:hover{
    color: inherit;
  }
}