.popup-wrapper {
  background-color: rgba(30, 30, 30, 0.8);
  padding: 2rem;
  border-radius: 1.5rem;
  position: absolute;
  top: 20%;
  bottom: auto;
  right: 0;
  left: 10%;
  width: calc(100% - 20%);
  z-index: 10;

  &.time-up {
    background-color: transparent;
    pointer-events: all;

    .close-btn {
      top: 0;
      right: 0;
    }
  }

  .popup-header {
    padding: 0 0.5rem;

    .close-btn {
      width: 3rem;
      position: absolute;
      right: 0.75rem;
      top: 1rem;
      cursor: pointer;
    }

    h2 {
      color: white;
      font-weight: bold;
    }
  }

  .hint-body {
    .row {
      margin: 1rem 0;
    }

    textarea, .scores, .hints {
      background-color: #D9D9D9;
      font-weight: bold;
      padding: 2rem;
    }

    textarea {
      min-height: 100%;

      &::placeholder {
        color: #1E1E1E;
        line-height: 1;
      }
    }

    .scores {
      padding: 1rem;
      text-align: center;
      border-radius: 0.375rem;

      h4 {
        font-weight: inherit;
        font-size: 1rem;
      }

      .coins {
        list-style: none;
        background-color: white;
        padding: 0.5rem;
        margin-bottom: 0;
        border-radius: inherit;

        li {
          cursor: pointer;
          opacity: 0.5;
          font-size: 0.75rem;
          line-height: 1.2;

          &.selected {
            opacity: 1;
          }
        }
      }
    }
  }

  .add-hint-btn {
    //background-color: #8CC63F; ToDo Check if needed to bring back https://brainshuttle.atlassian.net/browse/GLMS-197
    background-color: #67A74C;
    width: 100%;
    font-size: 2rem;
  }

  .hints {
    border-radius: 0.375rem;

    h2 {
      font-weight: inherit;
    }

    .coin-svg svg {
      max-width: 1.5rem;
    }
  }

  .instruction-body {
    .description {
      min-height: 38vh;
      background-color: white;
      border-radius: 0.375rem;
    }

    button {
      width: 44%;
      margin: 1rem 0;
      font-size: 1.75rem;
    }
  }
}