@use "mixins";
@import "functions";

.animate {
  @media all and (max-width: 1200px) {
    min-width: 100%
  }
}

.shop {
  gap: 0.5rem;
  background-color: #EDEDED;
  padding: 1rem;
  transition: 0.3s width ease-in-out;

  > div {
    > div {
      flex: 1;
    }
  }

  &-item {
    background-color: #1D1D1B;
    border-radius: 1.25rem;
    padding: 0.375rem;

    .img-wrapper {
      background-color: white;
      border-radius: 1rem;

      .coin-svg {
        position: absolute;
        max-width: 1.5rem;
        bottom: 0.5rem;
        left: 0.5rem;
      }
    }

    .buy-btn {
      background-color: #95C11F;
      padding: 0.2rem 2rem;
      font-size: 0.875rem;
      text-transform: capitalize;
      margin-top: 0.25rem;
      border-radius: 1rem;
      width: 100%;
    }
  }

  .swiper {
    width: 6rem;
    height: 100%;
  }

  .swiper-slide {

    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    height: 6rem;
  }

  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  --swiper-navigation-size: 1rem;

  .swiper-button-prev, .swiper-button-next {
    &:after {
      color: black;
    }
  }

  .swiper-button-next {
    right: 0.15rem;
  }

  .swiper-button-prev {
    left: 0.15rem;
  }

  .avatar-items {
    background-color: #E3E3E3;
    padding: 2rem 2rem 0 4rem;
    border-top-right-radius: 3rem;
    border-bottom-right-radius: 3rem;
    transform: translateX(-2rem);
    position: relative;
    width: 180px;
    @include mixins.box-shadow(0, 4px, 4px, rgb(0 0 0 / 25%));


    h4 {
      color: black;
      -moz-transform: translateX(-50%) translateY(-50%) rotate(-90deg);
      -webkit-transform: translateX(-50%) translateY(-50%) rotate(-90deg);
      transform: translateX(-50%) translateY(-50%) rotate(-90deg);
      position: absolute;
      top: 50%;
      left: 0;
      font-size: 1.2rem;
      font-weight: bold;
      text-transform: capitalize;
    }

    .item-tabs {
      position: absolute;
      right: -7.25rem;
      top: 2rem;

      .item-tab {
        height: auto;
        background-color: #E3E3E3;
        border-radius: 0 0 1rem 1rem;
        cursor: pointer;
        transition: all 0.3s;
        -moz-transform: translateX(-50%) translateY(-50%) rotate(-90deg);
        -webkit-transform: translateX(-50%) translateY(-50%) rotate(-90deg);
        transform: translateX(-50%) translateY(-50%) rotate(-90deg);
        margin: 5rem 0;
        padding: 0.25rem 0.5rem;
        text-align: center;
        font-weight: bold;
        box-shadow: inherit;

        &.active {
          background-color: #2A3447;
          color: white;
        }
      }
    }
  }

  .list-item {
    height: 145px;
  }
}

.avatar-container {
  transition: 0.3s all;

  .avatar-wrapper {
    background-color: #0B222A;
    border-radius: 1.5rem;
    padding: 1rem;
    z-index: 2;
    @include mixins.box-shadow(1px, 1px, 4px, rgb(158 145 141 / 75%));


    .avatar-img-wrapper {
      border-radius: 1rem;
      padding: 1rem;
      height: 100%;
      @media all and (min-width: 600px) {
        min-width: 13rem;
      }
    }
  }
}

.shop-block {
  h3 {
    font-size: 1.2rem;
    font-weight: bold;
  }
}

.shop-items {
  grid-template-columns:repeat(4, calc(25% - 11.5px));
  gap: 15px;
  @media all and (max-width: 1000px) {
    grid-template-columns:100%;
  }

  @media all and (min-width: 1000px) and (max-width: 1200px) {
    grid-template-columns:repeat(2, calc(50% - 8px));
  }

  .shop-item-container {

    > h4 {
      font-size: 1rem;
      font-weight: bold;
      text-transform: capitalize;
    }
  }
}

.list-item {
  display: flex;
  justify-content: center
}

.palette {
  --g: 3px; /* The gap between shapes*/
  --s: 18px; /* the size*/

  height: 115.5px;
  width: 115.5px;
  display: inline-block;
  border: 3px solid #fff;
  padding: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, .3), inset 0 0 10px rgba(0, 0, 0, .3);

  .item {
    top: 8px;
    left: 8px;
    right: 8px;
    bottom: 8px;
    border: var(--s) solid var(--color, red);
    transform: rotate(var(--rotate));
    border-radius: 50%;
    clip-path: polygon(
                    calc(50% + var(--g) / 2) 50%,
                    calc(50% + var(--g) / 2) 0%,
                    100% 0%,
                    100% calc(var(--clip-path-degree) - var(--g) / 2),
                    50% calc(50% - var(--g) / 2));

    &.active {
      box-shadow: inset 0 0 4px rgba(0, 0, 0, .5)
    }
  }

  &-btn {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #95C11F;
    width: 35%;
    height: 35%;
    filter: drop-shadow(1px 1px 4px rgba(158, 145, 141, 0.75));
  }
}