.memory-film-game.editor {
  .numbers-container {
    .matched {
      pointer-events: all;
    }
  }

  .card-back-face {
    color: #ffffff;
    img{
      max-height: 100%;
    }
  }

  .select-text {
    position: relative;
    &:after {
      content: "𝙸";
      position: absolute;
      right: 0;
    }
  }
  textarea{
    height: 100%;
    background-color: transparent;
    border: none;
    font-size: 1rem;
    resize: none;
  }
  .btn-save{
    background-color: #95c637;
    color:white;
    font-size: 1.5rem;
  }
  .image-btn{
    padding:0.5rem;
    cursor: pointer;
  }
  input[type="file"]{
    display: none;
  }
  .card{
    background-color: #006837;
  }
}
