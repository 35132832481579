.games-overview {
  h2{
    text-transform: uppercase;
    font-weight: bold;
  }
  .table {
    border: none;

    tr, th, td {
      border: inherit;

      > div {
        padding: 0.5rem 0;
        border-bottom: 1px solid;
      }
      div {
        display: flex;

      }

    }

    td {
      svg {
        width: 1.5rem;
        &.published-icon{
          height: 1.25rem;
        }
      }
    }

    th {
      >div{
        justify-content: space-between;
      }
      div{
        align-items: baseline;
      }
      svg {
        width: 1.25rem;

        &[class^='arrow-'], .filter-icon {
          height: 1rem;
        }
        &.published-icon{
          height: 1.5rem;
        }
      }
      span:first-of-type{
        margin-right: 0.5rem;
      }
    }
  }
  .filter{
    background-color: #006837;
    color: white;
    max-width: 20rem;
    min-height: 18rem;
    border-radius: 1rem;
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: fixed;
    left: 0;
    right: 0;
    top: 5%;
    margin: 0 auto;
    z-index: 10;
    .btn-success{
      background-color: #67A74C;
      border-color: #67A74C;
    }
    .filter-content{
      margin: 2rem 0;
    }
    .close-btn{
      width: 2rem;
    }
  }
  .filter-btn{
    cursor: pointer;
  }
}