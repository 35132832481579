$text-green:#006837;
//$editor-background:#8CC63F; ToDo Check if needed to bring back https://brainshuttle.atlassian.net/browse/GLMS-197
$editor-background:#67A74C;
$editable-input:#D9D9D92E;
$editable-textarea:#D9D9D95E;
//$light-green:#8CC63F; ToDo Check if needed to bring back https://brainshuttle.atlassian.net/browse/GLMS-197
$light-green:#67A74C;

//diamond colors

//green
$green-diamond :#A2FF5A;
$green-diamond-1 :#8ED84E;
$green-diamond-2 :#75BA4D;
$green-diamond-3 :#AAE6F9;

//purple

$purple-0 :#FF80F9;
$purple-1 :#CE5BBE;
$purple-2 :#A54998;
$purple-3 :#E065D5;

//
$description-color:#453232