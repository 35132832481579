.scramble-sentence{
  .img-wrapper{
    background-image: url("../../images/gamification/img-frame.svg");
    padding: 2rem;
  }
  color:#453232;
  .word{
    margin: 0.1rem 0.25rem;
    p{
      border: 1px solid;
      border-radius: 0.5rem;
      padding: 0 0.25rem;
    }
  }
  .drag-item.col-md-3.col-6.px-2 {
    width: auto;
  }
}