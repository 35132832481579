.essay-editor {
  .keywords-list {
    padding: 0 1rem;
    ul {
      display: flex;
      flex-wrap: wrap;
      justify-content:space-between ;
      li {
        list-style: disc;
        flex-basis: calc(50% - 1rem);
        word-break: break-all;
        &:first-of-type{
          margin-right: 0.5rem;
        }
      }
    }
  }
  textarea{
    min-height: 11rem;
  }
  .btn-add-keyword{
    background-color: #5DCCF5;
    color: white;
    text-transform: uppercase;
    width: 100%;
    margin: 1rem 0;
  }
}