
.buy-hints-popup {
  background-image: url("../images/gamification/clues-bg.svg");
  padding: 2.5rem;
  z-index: 10;
  position: fixed;
  border-radius: 0.75rem;
  left: 30%;
  top: 8%;
  bottom: auto;
  min-width: 18rem;
  max-width: 25rem;



  .btn-buy-hint {
    background-image: url("../images/gamification/buy-hint-btn.svg");
    color:white;
  }

  ul {
    color: black;
  }

  .costs-info {
    color: #006837;
    font-size: 1.2rem;
    font-family: Sniglet;
    span{
      font-weight: 800;
    }

    svg {
      width: 1.3rem;
    }
  }

  .opened-hint {
    font-family: Sniglet;
    background-color: white;
    border-radius: 0.75rem;
    color: #006837;
    min-height: 6rem;
    padding: 0.5rem;
    margin-bottom: 1.25rem;
  }
}