@import "functions";

.sidebar {
  background-color: #0B222A;
  height: 100vh;
  z-index: 3;
  transform: translateX(-100%);

  &-menu {
    width: toRem(200);
    height: 100vh;
  }

  &-img {
    width: toRem(120);
    height: toRem(120);

    .avatar {
      width: 70%;
      max-height: 70%;
      margin: 0;
      object-fit: contain;
      object-fit: contain
    }
  }

  ul {
    li {
      cursor: pointer;

      a {
        color: inherit;
      }
    }
  }

  h2 {
    font-weight: 700;
    margin-bottom: 0;
    line-height: 0.75;
    font-size: 1rem;
    color: white;
  }

  .info {
    &-wrapper {
      background-color: #B5CAD6;
      padding-top: 1.5rem;
      border-radius: 10px;
      margin-top: 2rem;
    }

    & {
      background-color: #0f2f3a;
      color: white;
      padding: 1.25rem;
      border-radius: 10px;
      position: relative;

      p {
        font-weight: bold;
        margin-bottom: 0;
        line-height: 1;
      }
    }
  }
}

.sidebar-mobile {
  z-index: 10000;
  nav {
    &.closed {
       max-height: 70px;
      .sidebar-menu{
        max-height: 70px;
        display: none!important;
      }
    }
    .sidebar-menu{
     // height: 100vh;
    }
  }

  .background {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 300px;
    background-color: #0B222A;
  }

  button {
    outline: none;
    border: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    cursor: pointer;
    position: absolute;
    top: 18px;
    left: 15px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: transparent;
  }

  ul,
  li {
    margin: 0;
    padding: 0;
  }

  ul {
    padding: 25px;
    //position: absolute;
    //top: 100px;
    //width: 230px;
  }

  li {
    list-style: none;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    cursor: pointer;

    a {
      color: white
    }
  }

  .icon-placeholder {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    flex: 40px 0;
    margin-right: 20px;
  }

  .text-placeholder {
    border-radius: 5px;
    width: 200px;
    height: 20px;
    flex: 1;
  }

  .refresh {
    padding: 10px;
    position: absolute;
    background: rgba(0, 0, 0, 0.4);
    border-radius: 10px;
    width: 20px;
    height: 20px;
    top: 10px;
    right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
}


