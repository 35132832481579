.highlight-word-editor, .scramble-sentence-editor {
  textarea {
    min-height: 27vh;
  }

  .btn-scramble {
    background-color: #5DCCF5;
    color: white;
    font-size: 1.5rem;
  }

  .sentence-to-scramble {
    padding: 0.5rem 1rem;

    span {
      margin-right: 0.3rem;
      cursor: pointer;

      &.selected, &.highlighted {
        padding: 0.05rem 0.2rem;
        border-radius: 0.1rem;
      }

      &.selected {
        background-color: rgb(217 217 217);
      }

      &.highlighted {
        background-color: #296835;
        pointer-events: none;
      }
    }
  }

  &.game {
    .sentence-to-scramble {
      .sentence-to-scramble {
        color: #453232;

        .highlighted {
          background-color: #AAE4F9;
          pointer-events: all;
        }
      }
    }
  }
}

