@import "functions";

body, html {
  height: 100%;
  width: 100%;
  font-family: 'Montserrat';

  #root {
    position: relative;
    transition: all 0.3s;

    &.open {
      padding-left: toRem(200);
    }
  }
}

body {
  @include scrollbar(rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.2));
  background: rgb(248, 249, 250);
  background: linear-gradient(90deg, rgb(202, 227, 252) 0%, rgb(173, 216, 230) 5%, rgb(11, 193, 228) 100%);
}

.loader {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #80808087;
}

.gameList {
  > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 15px 0;
    text-transform: capitalize;
  }
}

.option {
  margin: 0 0.5rem;
}

.btn-group {
  margin: 2rem 0;
}


.games-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.clues {
  display: none;
}

ul {
  padding: 0;
}

.quiz-option-container {
  display: flex;
  align-items: center; /* Vertically center the items */
  justify-content: space-between; /* Push items to the edges */
}

.quiz-delete-option-btn {
  background: none;
  border: none;
  cursor: pointer;

  &.btn {
    padding: 0 0.4rem 0.1rem;
  }

  svg {
    width: 1.625rem;
  }
}

.quiz-add-option-btn {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1rem; /* Adjust as necessary */
  color: white; /* Adjust color as necessary */
}

.editor {
  .custom-radio {
    [type="radio"]:checked,
    [type="radio"]:not(:checked) {
      display: none;
    }

    [type="radio"]:checked + label,
    [type="radio"]:not(:checked) + label {
      position: relative;
      padding-left: 28px;
      cursor: pointer;
      line-height: 20px;
      display: inline-block;
    }

    [type="radio"]:checked + label:before,
    [type="radio"]:not(:checked) + label:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 1.25rem;
      height: 1.25rem;
      border: 1px solid #ddd;
      border-radius: 100%;
      background: #fff;
    }

    [type="radio"]:checked + label:after,
    [type="radio"]:not(:checked) + label:after {
      content: '';
      width: 0.8rem;
      height: 0.8rem;
      position: absolute;
      top: 0.23rem;
      left: 0.23rem;
      border-radius: 100%;
      -webkit-transition: all 0.2s ease;
      transition: all 0.2s ease;
    }

    [type="radio"]:checked + label:after {
      background: $text-green;
    }

    [type="radio"]:not(:checked) + label:after {
      opacity: 1;
      -webkit-transform: scale(1);
      transform: scale(1);
      background: #B0BCBF;
    }
  }

  .custom-checkbox {
    label {
      position: relative;
      cursor: pointer;

      &:before {
        content: '';
        -webkit-appearance: none;
        background-color: #B3B3B3;
        border: 2px solid white;
        border-radius: 0.375rem;
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
        padding: 9.5px;
        display: inline-block;
        position: relative;
        vertical-align: middle;
        cursor: pointer;
        margin-right: 5px;
      }
    }

    input[type="checkbox"] {
      padding: 0;
      height: initial;
      width: initial;
      margin-bottom: 0;
      display: none;
      cursor: pointer;

      &:checked {
        & + label {
          &:after {
            content: '';
            display: block;
            position: absolute;
            top: 5.5px;
            left: 8px;
            width: 7px;
            height: 12px;
            border: solid #006837;
            border-width: 0 2px 2px 0;
            transform: rotate(45deg);
          }

          &:before {
            background-color: #D9D9D9;
          }
        }
      }
    }
  }
}

li {
  list-style: none;
}

.uploader {

  &.uploaded {
    position: relative;
    height: 0;

    overflow: hidden;

    .uploaded-img {
      border-radius: 1.2rem;
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      width: 100%;
      height: 100%;
      position: absolute;
      left: 50%;
      top: 50%;
      display: block;
      z-index: 1;
      -webkit-transform: translate(-50%, -50%);
      -moz-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      -o-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);

      img {
        object-fit: contain;
        border-radius: 1.2rem;
      }

      label {
        svg {
          display: none;
        }
      }

      &:hover {
        label {
          svg {
            display: block;
          }
        }
      }
    }
  }

  label {
    display: block;
  }

  input {
    display: none;
  }

  .upload-btn {
    position: absolute;
    top: calc(50% - toRem(40));
    max-width: toRem(55);
    bottom: 50%;
    left: calc(50% - toRem(40));
    cursor: pointer;

    &:hover {
      opacity: 0.5;
    }
  }
}

input, textarea {
  padding: 0 0.5rem;
  @include placeholderColor(white);
}

textarea {
  padding-top: 0.5rem;
  @include scrollbar(rgba(64, 38, 38, 0.1), rgba(50, 46, 46, 0.2));
}

.table {
  color: $text-green;
}

.animate {
  animation-duration: 0.5s;
  animation-name: animate-fade;
  animation-delay: 0.2s;
  animation-fill-mode: backwards;
}


@keyframes animate-fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.arrow-down {
  transform: rotate(180deg);
}

.btn {
  &.btn-filter, &.btn-edit {
    padding: 0;
    border: none;
  }
}

.toggler {
  position: absolute;
  width: 1rem;
  height: 5rem;
  background-color: white;
  right: -1rem;
  top: calc(50% - 40px);
  z-index: 100000;
  border-radius: 0 10px 10px 0;
  cursor: pointer;
  transition: all 0.3s;

  &:after {
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 4.5px 5px 4.5px 0;
    border-color: transparent #12161a transparent transparent;
    position: absolute;
    top: calc(50% - 8px);
    right: 6.5px;
    transform: rotate(180deg);
  }

}

.open {
  .toggler {
    &:after {
      transform: rotate(0deg);

    }
  }
}

.container {
  padding-left: 2rem;
  padding-right: 2rem
}


.alert-info {
  background-image: url("../images/gamification/ntf-bg.svg");
  background-size: 100% 100%;
  background-color: transparent;
  border: none;
  color: #1E1E1E;
  font-weight: bold;
}

.time-up-popup {
  background-image: url("../images/gamification/ntf-bg.svg");
  background-size: 100% 100%;
  padding: 3rem;
  text-align: center;
  position: relative;

  img {
    max-width: 6rem;
  }

  h3 {
    color: #1E1E1E;
    position: absolute;
    left: calc(48% - 4rem);
    top: 45%;
  }
}

