.battleships-editor {
  .hotspot {
    .numbers-container {
      span {
        flex: 1 1 9%;
      }
    }
    .img-wrapper{
      justify-content: center;
      img{
        max-height: 11rem;
        @media all and (max-width:767px){
          max-width: 6rem;
        }
      }
    }
  }
}