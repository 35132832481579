@import "functions";
.icon {
  display: block;
  margin: 0 auto;
}

.avatar {
  display: block;
  margin: 1rem auto;
  width:toRem(101);
}