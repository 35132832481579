.informative-game{
   div.d-flex {
     > div {
       flex: 1 1 0;
       margin: 0 0.725rem;

       audio {
         width: 100%;
       }
     }
   }
  &.editor{
    label, input[type="text"]{
      background-color: white;
      color: black;
      text-transform: uppercase;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 0.3rem;
      margin: 1rem 0;
      padding: 1rem;
      font-weight: bold;

    }
    input[type="file"]{
      display: none;
    }
    input{
      &::placeholder{
        color:black;
        text-align: center;
      }
    }
    label{
      cursor: pointer;
    }
    img{
      width: 1.25rem;
      margin-left: 0.5rem;
    }
  }
  .video-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    img, video{
      max-height: 38vh;
      object-fit: contain;
    }
  }
}
