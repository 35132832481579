@use './mixins';
@import "functions";

.bar-menu {
  @include mixins.box-shadow(0, 0.5rem, 0.5rem, rgb(0 0 0 / 25%), false);
  background-color: #EDEDED;
  border-radius: 0 0 1rem 1rem;
  width: 100%;
  padding: toRem(25);
  margin-bottom: 3rem;
  gap: toRem(20);
  z-index: 1100;

  .toggler {
    @include mixins.box-shadow(0.5rem, 0, 0.5rem, rgb(0 0 0 / 25%), false);
    transform: rotate(90deg);
    bottom: 0;
    top: calc(100% - 2.05rem);
    left: 50%;
    background-color: #EDEDED;
  }

  .list {
    &-title {
      font-size: 1rem;
    }

    &-lg-icon {
      height: 4rem;
    }

    &-sm-icon {
      height: 1rem;
    }
  }

  .bar-menu-content {
    opacity: 0;
    height: 0;
    margin-top: 2rem;
    border-top: 2px solid;

    .d-flex {
      align-items: center;
    }
  }
}


