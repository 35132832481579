@mixin scrollbar($trackBackground, $thumbBackground){
  &::-webkit-scrollbar {
    width: 1em;
  }

  &::-webkit-scrollbar-track {
    background-color: $trackBackground;
    border-radius: 1rem;
    margin: 1rem ;
    // box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }

  &::-webkit-scrollbar-thumb {
    background-color: $thumbBackground;
    border-radius: 1rem;
    // outline: 1px solid white;
  }
  &::-webkit-scrollbar-corner {
    background: rgba(0,0,0,0);
  }
}

@mixin placeholderColor($color){
  &::placeholder, ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: $color;
    opacity: 1; /* Firefox */
  }

  &:-ms-input-placeholder, :-ms-input-placeholder  { /* Internet Explorer 10-11 */
    color: $color;
  }

  &::-ms-input-placeholder,::-ms-input-placeholder  { /* Microsoft Edge */
    color: $color;
  }
}

@mixin transform($transforms) {
  -webkit-transform: $transforms;
  -moz-transform: $transforms;
  -ms-transform: $transforms;
  transform: $transforms;
}


@mixin transform-origin ($origin) {
  -webkit-transform-origin: $origin;
  -moz-transform-origin: $origin;
  -ms-transform-origin: $origin;
  transform-origin: $origin;
}

@mixin box-shadow($top, $left, $blur, $color, $inset: false) {
  @if $inset {
    -webkit-box-shadow:inset $top $left $blur $color;
    -moz-box-shadow:inset $top $left $blur $color;
    box-shadow:inset $top $left $blur $color;
  } @else {
    -webkit-box-shadow: $top $left $blur $color;
    -moz-box-shadow: $top $left $blur $color;
    box-shadow: $top $left $blur $color;
  }
}