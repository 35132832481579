.islands {
  min-height: 100vh;
  //padding-top: 3rem;
  display: flex;
  flex-direction: column;
  justify-content: center;

  svg {
    overflow: visible;
  }

  .skill-island {
    // transition: all 0.3s;
    cursor: pointer;
    position: absolute;
    transition: all 0.3s;

    &:hover {
      transform: scale(1.1);
      transform-origin: center;
      -moz-transform-origin: center;
      z-index: 10;
    }

    &.opening {
      position: fixed;
      animation: animate-zoom-in 0.6s 0.2s;
    }
  }
}

@keyframes animate-zoom-in {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(1.5);
    opacity: 0;
    position: fixed;
    display: block;
  }

}