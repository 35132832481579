@use 'mixins';

div {
  .modal-body {
    text-align: center;
  }

  &.quiz, &.true-false {
    .answers {
      padding: 0 4rem;
    }

    .modal-content {
      background-color: #102028;
      color: #ffff;

      svg {
        width: 100px;
        height: 120px;
      }

      h2 {
        position: relative;
        margin-bottom: 30px;

        &:after {
          position: absolute;
          width: 20%;
          height: 2px;
          background: white;
          content: "";
          bottom: -20px;
          left: 40%;
        }
      }

      p {
        padding: 3rem;
      }
    }
  }

  &.crossword {
    input {
      outline: none;
    }

    svg {
      overflow: visible;

      text:not([class^='guess-text-']) {
        //  transform: translate(4px, -5px);

        &[text-anchor="start"] {
          //  transform: translate(-3px, 3px);
        }
      }
    }

    .clues {
      display: none;
    }

    .clues-container {
      display: flex;
    }

    background-color: transparent;

    .modal-content {
      background-color: transparent;
      border: none;
    }


    .across, .down {
      display: flex;
    }
  }
}

.drag-drop {
  p {
    margin-bottom: 0;
  }

  &.drag-text {
    .col {
      > div {
        padding: 0.5rem;
        height: 100%;
      }
    }

    .drop-area {
      text-align: center;
      color: white;
      font-weight: bold;
      //border: 0.5px solid;
      //border-radius: 5px;
      display: inline-block;
      width: auto;
      height: toRem(30);
      min-width: toRem(100);
      padding: 0 0.2rem;
      line-height: 2;


      //  background-color: white;
      &:before {
        content: "\200b"; // unicode zero width space character
      }


      &.is-over {
        opacity: 0.5;
        // border-width: 3px;
      }
    }

    .missing-words {
      display: grid;
      grid-template-columns: repeat(auto-fill, 24%);
      column-gap: 0.5rem;
      margin-top: 0.5rem;

      span[draggable="true"] {
        //  background-color: #ffffff;
        border-radius: 3px;
        padding: 0.2rem;
        margin: 0.5rem 0;
        min-width: 8rem;
        text-align: center;
        //color: $text-green;
      }
    }
  }
}


.essay {
  .highlight {
    background-color: #61dafb;
  }

  input {
    background-color: #D9D9D9;
  }
}

.memory-game {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(3, 1fr);

  .card {
    width: toRem(180);
    height: toRem(250);
    border-radius: 4px;
    @include mixins.box-shadow(2px, 2px, 4px, 4px, #DEDEDE);
    transition: 0.3s;
    transform-style: preserve-3d;
    position: relative;
    cursor: pointer;
    margin: 5px;

    img {
      width: 95%;
      height: 95%;
    }

    p {
      padding: 0 0.5rem;
      color: $text-green;
      max-height: 100%;
      overflow-y: auto;
      @include scrollbar(rgba(64, 38, 38, 0.1), rgba(50, 46, 46, 0.2));
    }

    .card-face {
      backface-visibility: hidden;
      position: absolute;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      &.card-back-face {
        transform: rotateY(180deg);
      }
    }

    &.is-flipped {
      transform: rotateY(180deg);
    }

    &.is-inactive {
      // visibility: hidden;
      opacity: 0;
    }
  }

}

.jumble-word {
  li {
    display: inline-block;
    border: 1px solid;
    padding: 1.5rem;
    margin: 0.5rem;
  }

  .game-area {
    justify-content: space-between;
    display: flex;
    flex-direction: column;
  }

  .jumbled-letters {
    text-align: center;
    min-height: 2rem;

    > span {
      display: inline-block;
      font-size: 2rem;
      transition: all 0.3s;
    }
  }

  .drop-area {
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: auto;
    column-gap: 0.5rem;
    width: 100%;
    transition: all 0.3s;

    > span:not([draggable="true"]) {
      display: inline-block;
      height: 3rem;
      width: 100%;
      border-bottom: 1px solid;
      text-align: center;

      > span {
        font-size: 1.5rem;
      }
    }
  }
  .jumble-description{
    font-size: 1.5rem;
  }
}

.fill-in-game {
  .description {
    font-family: Sniglet;
    //max-height: 14.9rem;
    overflow-y: auto;

    @include scrollbar(rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.2));

    p {
      color: #453232;
    }
  }

  video {
    border-radius: 5px;
  }

  .inputs {
    white-space: pre;
  }

  .blank-input {
    background-image: none;
    background-color: transparent;
    box-shadow: none;
    border: 3px solid #453232;
    border-radius: 0.3rem;
    line-height: 1;
    width: 2.5rem;
    height: 2.5rem;
    margin: 0.05rem;
    color: inherit;
    text-align: center;

    &:focus-visible {
      outline: none;
    }
  }
}

.game-wrapper {
  border-radius: 1.5rem;
  background-color: rgb(204 204 204 / 45%);
  color: white;
  position: relative;

  .game-info {
    padding: 1rem 2rem 1rem;
  }

  .close-btn, .info-btn {
    position: absolute;
    right: toRem(10);
    width: toRem(32);
    cursor: pointer;
  }

  .close-btn {
    top: toRem(10);
  }

  .info-btn {
    top: toRem(50);
  }

  .title {
    font-size: toRem(32);
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 0;
    max-width: calc(100% - 0.75rem);
  }

  .game-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: inherit;
    //background-color: #5f7f23; ToDo check if needed to bring back https://brainshuttle.atlassian.net/browse/GLMS-197
    background-color: #67A74C;
    padding: 3rem 2rem 9rem;
    // min-height: toRem(416);
    // overflow-y: auto;
    //max-height: calc(100vh - 17rem);

    .game-content {
      max-width: 100%;
      overflow-y: visible;
      overflow-x: auto;
      @include scrollbar(rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.2));

      .row {
        margin: 0;
      }
    }

    &:not(.editor) {

    }
  }

  .btn-wrapper {
    text-align: right;
    padding: 2rem 0 0;

    .btn-success {
      font-size: 1.75rem;
    }
  }
}

.calculation-game {
  h2 {
    white-space: break-spaces;
  }
}

.hotspot {
  .numbers-container {

    span {
      background-color: #09AEE2;
      margin: 0.1rem;
      padding: 0.3rem 0;

      &.selected {
        background-color: #6E4C31;
      }
      &.active{
        background-color: #006837;
      }
    }
  }

  .images {
    .img-wrapper {
      display: flex;
      align-items: center;
      height: 100%;
      padding-bottom: 1rem;
      border-bottom: 2px solid;
    }

    p {
      text-align: center;
    }
  }
}


.drag-image {
  .categories {
    display: flex;
    justify-content: center;
    max-width: 100%;
    overflow-x: auto;
    padding-bottom: 1rem;
    @include scrollbar(#FFF1D9, #453232);

    .drop-area {
      &.is-over {
        opacity: 0.5;
      }
    }

    .category {
      font-family: Sniglet;
      color: $description-color;
      background-image: url("../images/gamification/drop-img-bg.svg");
      background-size: cover;
      padding: 2rem;
      margin: 0 1rem;
      text-align: center;
      text-transform: capitalize;
      font-size: 2rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .options {
    margin: 1rem;
    row-gap: 2rem;
    span.drag-item {
      border-radius: 7px;
      background-color: #D9D9D9;
      position: relative;
      transition: all 0.3s;
      display: flex;
      align-items: center;
      @media all and (max-width: 991px) {
        padding: 0.5rem;
      }

    }
  }
}


.numbers-container {
  display: flex;
  flex-wrap: wrap;

  span {
    flex: 1 1 4.6%;
    margin: 0.1rem;
    text-align: center;
    padding: 0.3rem 0;
    cursor: pointer;
    font-weight: bold;
  }
}

.memory-film-game {
  & + .btn-wrapper.with-count {
    position: unset;
  }

  .count {
    position: relative;
    z-index: 10;
    padding: 0.875rem 1.5rem 0.5rem;
  }

  .numbers-container {
    span {
      flex: 1 1 11.5%;
      background-image: url("../images/green-cell.svg");
      // background-color: $text-green;

      &.selected {
        background-image: url("../images/black-cell.svg");
        // background-color: #373538;
      }

      &.matched {
        background-image: url("../images/purple-cell.svg");
        // background-color: #86C50A;
      }

      &.selected, &.matched {
        pointer-events: none;
      }
    }
  }

  .cards {
    height: 100%;

    div {
      height: inherit;
    }

    .card {
      border: none;
      color: $text-green;

      // @include mixins.box-shadow(0, 4px, 4px, 0, rgb(0 0 0 / 25%));
      width: 100%;
      height: 100%;
      min-height: 100px;
      display: flex;
      flex-direction: column;
      font-weight: bold;
      font-size: toRem(20);
      justify-content: center;
      align-items: center;
      transition: 0.3s;
      transform-style: preserve-3d;
      position: relative;

      .card-face {
        backface-visibility: hidden;
        position: absolute;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        padding: 0 0.5rem;

        &.card-back-face {
          transform: rotateY(180deg);
        }


        &.card-back-face {
          transform: rotateY(180deg);
        }
      }

      p {
        max-height: 100%;
        overflow: auto;
      }

      &.is-flipped {
        transform: rotateY(180deg);

        &.brown {
          background-color: #DD9D6E;
        }

        &.yellow {
          background-color: #C3CE39;
        }
      }
    }

    .col {
      position: relative;
    }
  }
}

.btn-wrapper.with-count {
  margin-top: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute; // TODO check the styles with removed positioning
  bottom: 3rem;
  right: 2rem;
  left: 2rem;
  background-color: $light-green;


  h4 {
    margin: 0;
  }

  .btn-hint {
    border: 1px solid white;
    color: white;
    font-size: 1.75rem;
    margin-right: 1rem;
  }
}

.selected-island {
  min-height: 65vh;
  position: relative;


  .warning {
    font-size: 2.5rem;
    text-transform: uppercase;
  }


  .score-info {
    color: $text-green;
    margin-top: 1rem;
  }

  .btn-light {
    color: $text-green;
  }

  .btn-wrapper {
    margin: 2rem 0;

    .btn:first-of-type {
      margin-right: 0.5rem;
    }

    button {
      font-size: 1.5rem;
    }
  }

  .close-btn {
    max-width: 2rem;
    margin-bottom: 1rem;
    cursor: pointer;
    position: absolute;
    width: 100%;
    right: 5rem;
    top: 3.5rem;
  }

}

.diamond-btn, .gem-wrapper {
  cursor: pointer;
}

.close-btn {
  width: 3rem;
  position: absolute;
  right: 0.75rem;
  top: 1rem;
  cursor: pointer;
}

.skill-title {
  display: block;
  margin: 1rem auto;
  max-width: 7rem;
}

.skill-island-svg {
  max-height: calc(100vh - 11rem);
  overflow: visible;

  &:focus {
    outline: none;
  }
}

.info-tooltip {
  background-color: #96CE1B;
  border-radius: 1rem;
  width: toRem(300);
  height: toRem(140);
  position: fixed;
  color: white;
  font-size: 1.25rem;
  padding: 0.5rem;
  margin-left: 1rem;
  @include mixins.box-shadow(-4px, 4px, 0px, rgba(102, 166, 9, 0.78));

  .triangle {
    position: absolute;
    bottom: -2.1em;
    left: calc(50% - 13.75px);
    width: 0;
    height: 0;
    border-width: 42px 22.5px 0 22.5px;
    border-color: #96CE1B transparent transparent transparent;
    border-style: solid;
    filter: drop-shadow(-3px 3px 0px rgba(102, 166, 9, 0.78));

  }

  &.left {
    .triangle {
      border-width: 21px 45px 21px 0;
      bottom: calc(50% - 13.75px);
      right: -2.1em;
    }
  }

  &.right {
    .triangle {
      border-width: 21px 0 21px 45px;
      bottom: calc(50% - 13.75px);
      left: -2.1em;
    }
  }

  h2 {
    font-size: 1.5rem;
    overflow: hidden;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    display: -webkit-box;
  }

  .level-info {
    h3 {
      font-size: 1.25rem;
    }

    .level-stars {

      svg {
        width: 1rem;
      }
    }
  }
}

.level-info {
  display: flex;
  align-items: center;
  margin-top: 1rem;

  h3 {
    margin-bottom: 0;
  }

  .level-stars {
    display: flex;
    padding: 0 1rem;
    align-items: center;
    height: 100%;
    flex-basis: 20%;

    svg {
      width: 33.3%;
    }
  }
}

.timer {
  background-image: url("../images/timer-bg.svg");
  font-family: Slackey;
  font-size: toRem(28);
  position: absolute;
  bottom: -2rem;
  left: 3rem;
  padding: 6rem 2rem 1rem;
  z-index: -1;
}

.tox-sidebar-wrap *{
  background: rgba(217, 217, 217, 0.1803921569) !important;
}
