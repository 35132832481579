@import "../mixins";
@import "../functions";


.simulation-editor {
  .dialogue-content{
    padding: 0 1.75rem 0 3.75rem;
    //display: flex;
    //.first,.second{
    //  flex-grow: 1;
    //}
  }
  .uploaded{
    padding-bottom: 60%;
  }
  .btn-correct{
    background-color:#5DCCF5;
    color: white;
  }
  .form-control{
    margin: 1rem 0;
    @include placeholderColor(white);

  }
  .answer-btns{
    display: flex;
    button{
      flex-grow: 1;
      &:first-of-type{
        margin-right: 1rem;
      }
    }
  }
  .swiper-container{
    margin: 1rem 0;
  }
  .dialogues {
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    padding: 0 2rem;



    .dialogue {
      background-color: white;
      position: relative;
      padding: 1rem;
      flex-grow: 1;

      &:before {
        content: "";
        position: absolute;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 10px 20px 10px 0;
        border-color: transparent #ffffff transparent transparent;
        right: 100%;
        bottom: 0.725rem;
      }

      p {
        background-color: #D9D9D9;
        padding: 0.5rem;
        font-weight: bold;
        &:last-of-type {
          margin-bottom: 0;
        }

        &.correct {
          color: #3C8731;
        }
        &.incorrect{
          color:#86C50A;
        }
      }
      &.first-player{
        margin-right: 1.625em;
      }
      &.second-player{
        margin-top:1.25rem;
      }
    }
  }
  .swiper {
    margin: 2rem 0;
    padding-left: 2rem;
    width: 100%;
    height: toRem(380);
  }

  .swiper-slide {
    text-align: center;
    font-size: 18px;
  }
  .swiper-pagination{
    left: 0;
    span{
      width: 0.75rem;
      height: 0.75rem;
    }
  }
}