@use 'mixins';
@import "mixins";
@import "functions";
@import "variables";


.gamified {

  font-family: 'Slackey';

  &.game-wrapper {
    background-color: transparent;

    .game-info {
      background-image: url("../images/Modo_de_aislamiento.svg");
      background-size: 100% 100%;
      padding: 2rem 4rem 6rem;
      margin: 0 3rem -6rem;

    }

    .close-btn, .info-btn {
      position: absolute;
      right: toRem(80);
      width: toRem(48);
      cursor: pointer;
    }

    .close-btn {
      top: toRem(30);
      font-size: 2rem;
    }

    .game-container {
      background-image: url('../images/sea_adventure.svg');
      padding: 0;
      background-color: transparent;
      background-size: 100% 100%;

      .game-content {
        padding: 6rem 4rem 17rem;
        color: #453232;
        font-family: Sniglet;
      }
    }

    .btn-wrapper.with-count {
      bottom: 11rem;
      right: 7rem;
      left: 5rem;
      background-color: transparent;
      color: $description-color;
      font-family: Sniglet;


      .btn-hint {
        color: #9D0000;
        border: none;
      }
    }

    .right-btn {
      font-size: 1.75rem;
    }
  }


  .selected-island {
    background-size: 100% 100%;
    background-image: url("../images/sea_adventure.svg");
    padding: 5rem;
    font-family: Sniglet;

    .left-btn {
      color: #2B6A10;
    }

    h2 {
      color: #3C2415;
      font-family: Slackey;
      font-size: 2.5rem;
    }

    h2, h4 {
      font-weight: bold;
      margin-bottom: 0;
      text-shadow: 0 4px 4px rgb(0 0 0 / 25%);
    }

    p {
      color: white;
      font-size: 1.5rem;
      line-height: 1;
      white-space: pre-wrap;
    }

    .btn {
      padding: 1rem 1.5rem;
      @media (max-width: 991px) {
        font-size: 1.25rem;
      }
    }

    .warning-wrapper {
      position: absolute;
      left: 20%;
      right: 20%;
      padding: 4rem;
      border-radius: 1rem;
      text-shadow: 0 4px 4px rgb(0 0 0 / 25%);
      text-align: center;

      h2 {
        font-size: 3rem;
      }

      h3 {
        font-family: 'Slackey';
        font-size: 2.5rem;
        color: white;

      }

      &.success {
        background-color: rgba(0, 104, 55, 0.25);

        h2 {
          color: $text-green;
        }
      }

      &.fail {
        background-color: rgba(157, 0, 0, 0.25);

        h2 {
          color: red
        }
      }
    }

  }

  .left-btn, .right-btn {
    border: none;
    background-color: transparent;
    font-family: Slackey;
  }

  .left-btn {
    background-image: url("../images/left-btn.svg");
  }

  .right-btn {
    background-image: url("../images/right-btn.svg");
    color: #2B6A10;
  }

  .close-btn {
    background-image: url("../images/circle.svg");
    font-family: Slackey;
    color: #9D0000;
    text-align: center;
    font-size: 2rem;
    display: flex;
    justify-content: center;
    padding: 0 1.5rem;
  }


  .image-container {
    position: relative;
    width: 100%;
    cursor: zoom-in;

    .img-frame {
      background-size: 100% 100%;
      background-image: url("../images/gamification/img-frame.svg");
      padding: 2.5rem;
      text-align: center;

      img {
        max-height: 38vh;
        object-fit: contain;
      }

    }
    &.open {
      cursor: zoom-out;

      .shade {
        pointer-events: auto;
        opacity: 1;
      }
      .img-frame{
        visibility: hidden;
      }

      img {
        visibility: visible;
        position: fixed;
        width: auto;
        height: auto;
        max-width: 100%;
        margin: auto;
        z-index: 1;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
      }
    }

    .shade {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      pointer-events: none;
      opacity: 0;
      background: rgba(0, 0, 0, 0.7);
      z-index: 1;
    }
  }


  input:not(.blank-input, [aria-label="crossword-input"]) {
    background-size: 100% 100%;
    background-image: url("../images/gamification/input-bg.svg");
    background-color: transparent;
    border: none;
    padding: 1rem;
    font-size: 1.5rem;

    &:focus {
      background-color: transparent;
      box-shadow: none;
    }
  }

  .sentences, .drop-text-to-image, .missing-words {
    span.drop-area, span[draggable="true"] {
      background-size: 100% 100%;
      background-image: url("../images/droppable-bg.svg");
      padding: 0 1rem;
      margin: 1rem;
      color: #8C6226;
      @media all and (max-width: 767px) {
        margin: 0.25rem
      }
    }

    span[draggable="true"] {
      cursor: grab;
    }
  }

  .custom-radio, .custom-checkbox {
    input:checked,
    input:not(:checked) {
      display: none;
    }

    input:checked + label,
    input:not(:checked) + label {
      position: relative;
      padding-left: 3rem;
      cursor: pointer;
      line-height: 1.5;
      display: inline-block;
    }

    input:checked + label:before,
    input:not(:checked) + label:before {
      background-size: 100% 100%;
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 2.5rem;
      height: 2.5rem;
      background-image: url("../images/circle.svg");
    }


    input:not(:checked) + label:after {
      opacity: 1;
      -webkit-transform: scale(1);
      transform: scale(1);
      background: #B0BCBF;
    }

    input:not(:checked) {
      + label span.check-wrapper {
        display: none;
        opacity: 0;
      }
    }

    input:checked {
      + label span.check-wrapper {
        opacity: 1;
        width: 1.75rem;
        height: 1.75rem;
        position: absolute;
        top: 0.35rem;
        left: 0.35rem;
        border-radius: 100%;
        transition: all 0.2s ease;
        background-color: $text-green;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          max-width: 1rem;
        }
      }
    }
  }

  .question-container {
    font-size: 1.5rem;
  }

  .clue-cell {
    width: 1rem;
    height: 1rem;

    rect {
      fill: url(#background-image)
    }
  }

  .hotspot {
    ul {
      padding-left: 1rem;

      li {
        font-size: 1.25rem;
        list-style: disc;
      }
    }

    .images {
      p {
        font-size: 1.5rem;
      }
    }

    .numbers-container {
      color: white;

      span {
        background-size: 100% 100%;
        background-color: transparent;
        background-image: url("../images/blue-cell.svg");

        &.selected {
          background-color: transparent;
          background-image: url("../images/purple-cell.svg");
        }
      }
    }
  }

  .btn-danger, .btn-hint {
    &:hover, &.selected {
      color: white !important;
      background-image: url("../images/gamification/btn-red-hover.svg") !important;
    }
  }

  .btn-success {
    &:hover, &.selected, &:active {
      color: white;
      background-image: url("../images/gamification/btn-green-hover.svg");

    }
  }

  .btn {
    background-size: 100% 100%;

    &:active {
      background-color: transparent;
    }
  }

  .simulation-dialogue {
    margin-top: 2rem;

    .btn-simulation-select {
      background-image: url("../images/gamification/simulation-ok-btn.svg");
      color: white;
      margin: 1rem 0;
      font-size: 1.5rem;
      text-align: right;
    }

    .bubble-wrap {
      background-image: url("../images/gamification/simulation-options-container-bg.svg");
      background-size: 100% 100%;
      transition: all 0.1s ease-in;


      ul {
        padding: 1rem;

        li {
          font-size: 1.5rem;
          margin: 0.5rem;
          text-align: center;
          color: white;
          background-image: url("../images/gamification/simulation-opt.svg");
          background-size: 100% 100%;
          cursor: pointer;

          &.selected, &:hover {
            background-image: url("../images/gamification/simulation-selected-opt.svg");
          }
        }
      }

    }
  }

  .buy-hints-popup {
    .close-btn {
      top: -1rem;
      right: -1.5rem;
      font-size: 1.75rem;
    }

    li {
      p.custom-checkbox {
        font-size: 2rem;

        label {
          font-weight: bold;
          line-height: 1;

          &:before {
            width: 2rem;
            height: 2rem;
          }

          .check-wrapper {
            width: 1.5rem;
            height: 1.5rem;
            top: 0.25rem;
            left: 0.25rem;
            color: white;
            font-size: 1.2rem;
            font-weight: bold;
          }
        }

        span {
          font-family: Sniglet;
          font-size: 1.75rem;
          color: #006837;
          font-weight: 400;
        }

        svg {
          width: 2rem;
        }
      }
    }
  }

  .clues-info {
    position: fixed;
    background-image: url("../images/gamification/clues-bg.svg");
    background-size: 100% 100%;
    min-height: 5rem;
    top: 0;
    left: 50%;
    bottom: auto;
    background-color: transparent;
    padding: 7rem 3rem 5rem;
    z-index: 1;

    .container {
      max-height: 69vh;
      overflow-y: scroll;
      @include scrollbar(#D9D9D9, #EEEEEE);
    }

    ul {
      max-height: fit-content;
    }

    .close-btn {
      right: 0;
      top: 0;
      font-size: 3rem;
      width: 4.5rem;
    }
  }

  .memory-film-game {
    .cards {
      .card {
        background-color: transparent;
        background-size: 100% 100%;

        &-1:not(.is-flipped) {
          background-image: url("../images/gamification/memory-card-1.svg");
        }

        &-2:not(.is-flipped) {
          background-image: url("../images/gamification/memory-card-2.svg");
        }

        &.is-flipped {
          background-image: url("../images/gamification/drop-img-bg.svg");
        }

        .card-back-face {
          width: calc(100% - 3.5rem);
          height: calc(100% - 4rem);
          border-radius: 0.5rem;
          @include mixins.box-shadow(0, 4px, 4px, rgb(0 0 0 / 25%));
        }

        &-1 .card-back-face {
          background-color: #D1B583;
        }

        &-2 .card-back-face {
          background-color: rgba(78, 166, 54, 0.6);
        }
      }
    }
  }

  .btn-show-clues {
    background-image: url('../images/circle.svg');
    background-size: 100% 100%;
    width: 4rem;
    height: 4rem;
    border-radius: 50%;
    align-items: center;
    display: flex;
    justify-content: center;
    font-size: 2.5rem;
    color: #4D4D4D;
    font-family: 'Slackey';
    cursor: pointer;
    border: none;
  }

  .btn {
    border: none;
    background-color: transparent;
    font-size: 1.75rem;
  }

  span[draggable='true'] {
    cursor: grab;
  }

  .configs {
    margin: -5rem 2rem 0;
    background-color: #C3C8CA;
    border-radius: 1rem;
    font-family: 'Montserrat';
    color: #4A4747;
    font-size: 1rem;
    opacity: 1;
    padding-top: 6rem;

    input {
      background-image: none;
      font-weight: 400;
      min-width: auto;
      padding: 0.5rem;
      font-size: 1rem;
      line-height: 0.5;

      &:focus {
        background-color: rgb(217 217 217 / 53%);
      }
    }

    input, select, textarea {
      background-color: #D9D9D9;
    }

    textarea {
      border-radius: 1.5rem;
    }
  }

  .quest-popup {
    background-image: url("../images/gamification/ntf-bg.svg");
    background-size: 100% 100%;
    color: #1E1E1E;
    padding: 5rem;
    position: absolute;
    top: 5%;
    left: 16%;
    max-width: 70%;

    .btn {
      color: white;
      padding: 0.5rem 4rem;
    }

    .quest-left-btn {
      background-image: url("../images/gamification/btn-red-bg.svg");
    }

    .quest-right-btn {
      background-image: url("../images/gamification/buy-hint-btn.svg");
    }

    svg {
      max-width: 4rem;
    }

    .img-frame {
      margin-bottom: 0.5rem;
    }

    .close-btn {
      line-height: 2;
      height: 4rem;
      width: auto;
      right: 0;
      top: 0;
    }
  }

  .btn-results {
    color: #571500;
    font-size: 1rem;
    font-weight: 400;
  }

  .results-popup {
    background-image: url("../images/gamification/results-bg.svg");
    background-size: 100% 100%;
    min-width: 60%;
    padding: 6rem;
    position: absolute;
    right: 8%;
    top: 5%;
    z-index: 10;

    .close-btn {
      font-size: 1rem;
      line-height: 3;
      top: -1rem;
      right: 0;
    }
  }

  .essay {
    p {
      font-size: 1.25rem;
    }
  }

  .uploaded {
    padding-bottom: 70%;

    .uploaded-img {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: transparent;
    }
  }

  .answers {
    .left-btn {
      color: #2B6A10;

      &:hover {
        color: #ffffff;
      }
    }

    .right-btn {
      color: #9D0000;
    }
  }

}

