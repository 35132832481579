@import "variables";


.main-screen.editor {
  //background-color: #8cc63f; ToDo Check if needed to bring back https://brainshuttle.atlassian.net/browse/GLMS-197
  background-color: #67A74C;
  max-height: calc(100vh - 14rem);
  padding: 2rem 0;

  .info-btn {
    top: 3.55rem;
    right: 2rem;
  }

  input {
    width: 100%;
  }

  .row {
    margin: 1rem 0;

    h3 {
      color: $text-green;
      font-weight: bold;
    }

    p {
      margin-bottom: 0.5rem;
    }

    label {
      text-transform: capitalize;
      font-weight: 500;
      font-size: 1.5rem;
    }
  }

  select {
    color: white;
    font-weight: bold;
    border-radius: 0.75rem;
    border: 1px solid;
    background: transparent url(../images/arrow-down.svg) no-repeat 125%;
    background-size: 50% 50%;
    cursor: pointer;
    text-transform: capitalize;
    font-size: 1.25rem;
    padding-right: 2.55rem;
    option{
      background-color: #D9D9D9;
      color:#353535;
      font-size: 1rem;
    }
  }
}
