.form-check{
  display: flex;
  label{
    margin-left: 0.5rem;
  }
}
.modal-header{
  border:none
}

.btn-check:checked+.btn, .btn.active, .btn.show, .btn:first-child:active, :not(.btn-check)+.btn:active{
  border:none
}

.btn-outline-success{
  color: #006837;
  border-color: #006837;
}
.btn-success{
  background-color: #006837;
}
.btn-danger{
  background-color:#FF0000;
}
.btn-outline-danger{
  color: #FF0000;
  border-color: #FF0000;
}
.btn{
  font-weight: bold;
  text-transform: uppercase;
  padding: 0.35rem 2rem;
}