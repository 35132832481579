@media (max-width: 991px) {
  .gamified{
    &.game-wrapper{
      .img-frame{
        margin-bottom: 1rem;
      }
    }
  }
}

@media (max-width: 767px) {
   html{
     font-size: 12px;
   }
    .container{
      padding-left: 1rem;
      padding-right: 1rem;
    }
  .gamified {
    &.game-wrapper {
      .game-info{
        margin-left:1rem;
        margin-right:1rem;
        padding: 3rem 2rem 6rem;
      }

      .close-btn{
        right: 1.5rem;
      }
      .game-container {
        .game-content {
          padding: 5rem 1rem 13rem;
          .custom-radio{
            label{
              &:before {
                width: 2rem;
                height: 2rem;
              }

              .check-wrapper {
                width: 1.5rem;
                height: 1.5rem;
                top: 0.25rem;
                left: 0.25rem;
              }
            }
          }
        }
      }

      .btn-wrapper {
        &.with-count {
          bottom: 10%;
          right: 3.5rem;
          left: 2.5rem;


        }
      }
    }
    .img-frame{
      padding:3.5rem;
    }
    .selected-island{
      padding-left: 2rem;
      padding-right: 2rem;
      .island-svg{
        height: 20vh;
      }
      .btn{
        padding: 0.75rem 1rem;
      }

      .close-btn{
        right:0.5rem;
        top:1rem;
      }
      .warning-wrapper{
        left:10%;
        right:10%
      }
      & ~ .configs{
        padding-left: 1rem;
        padding-right: 1rem;
        margin-left: 1rem;
        margin-right: 1rem;
        .config-item{
          display: block;
        }
      }
    }
    .clues-info{
      right:0;
      .close-btn{
        right:0;
        font-size: 2rem;
        width: 2.5rem;

      }
    }
    .buy-hints-popup{
      left: 12%;
      right: 12%;
    }
    .drag-drop.drag-text {
      .missing-words{
        margin-bottom: 4rem;
        grid-template-columns: repeat(auto-fill, 31%);
        span{
          font-size: 1.25rem;
        }
      }
    }
  }
}

@media (max-width: 575px) {
  .gamified{
    &.game-wrapper{
      .btn{
        padding: 0.35rem 1.1rem;
        &.left-btn{
          margin-right: 0.5rem;
        }
      }

    }
  }
}