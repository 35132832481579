.word-search {
  .no-events {
    opacity: 0.8;
    cursor: not-allowed;

    > div {
      pointer-events: none;
    }
  }

  .cell {
    background-image: url("../images/green-cell.svg");
    font-weight: 900;
    //width: 40px;
    //height: 40px;
    text-align: center;
    margin-left: 0;
    margin-top: 0;
    line-height: 40px;
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    box-sizing: border-box;
    transition: all 0.1s;
    user-select: none;
    color: #555;
    flex: 1 1 0px;


    &:hover {
      background-color: #11a7e1;
      color: #fcfcfc;
      cursor: pointer;
    }

    &.highlighted {
      color: white;
      background-image: url("../images/purple-cell.svg");
    }
  }


  .row-el {
    display: flex;
    width: auto;
  }

  .grid {
    width: auto;
    margin: auto;
  }


  ul, li {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  #word-list {
    letter-spacing: 1px;
    padding: 10px;
  }

  #word-list li {
    font-size: 1.5rem;
    color: #3a853a;
  }

  .ws-my-page-success-toast {
    width: auto;
    background-color: #7c6;
    position: relative;
    margin: auto;
    text-align: center;
    padding: 10px;
    color: #fff;
  }

  .wrd {
    background-color: #000
  }
}

.gamified {
  .word-search {
    .clues-info {
      padding: 3rem 5rem !important;
      right: 2rem;
      left: auto;
      top: 5rem;

      .close-btn {
        right: -1rem;
        top: -1rem;
        font-size: 2rem;
        width: 3rem;
      }
    }
  }
}