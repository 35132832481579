.word-search-editor {
  input{
    &::placeholder{
      color: rgba(255, 255, 255, 0.6);
    }
  }
  .word-list {
    padding: 2rem 2rem 0;

    ul {
      display: flex;
      flex-wrap: wrap;

      li {
        list-style: disc;
        max-width: 50%;
        flex-basis: 100%;
      }
    }
  }
}