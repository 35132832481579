.gamified {
  .drop-text-to-image {
    .droppable-item {
      padding: 0.5rem 1rem;
    }

    span.drop-area {
      background-image: none!important;
    }
  }
}